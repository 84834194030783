import React, { useEffect, useState } from "react";
import "./App.css";
import LoadingDice from "./component/dice/LoadingDice";
import RouterNavigation from "./navigation/RouterNavigation";
import RouterNavigationAuth from "./navigationAuth/RouterNavigation";
import { useAuth, AuthContextProvider } from "./context/AuthContext";
import { getUser } from "./lib/api/user";

function App() {
  const [user, setUser] = useState(undefined);
  const { authToken, userId, removeAuthToken } = useAuth();
  const [invitationCode, setInvitationCode] = useState(null);
  const [timeZone, setTimeZone] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('invitationCode');
    setInvitationCode(code);
  }, []);

  useEffect(() => {
    setUser(undefined);
    if (authToken && userId) {
      getUser(authToken, userId)
        .then((res) => {
          if (res === undefined) {
            setUser(null);
            removeAuthToken();
          } else {
            setUser(res);
          }
        })
        .catch((e) => {
          if (e) {
            setUser(null);
            removeAuthToken();
          }
        });
    } else {
      setUser(null);
    }
  }, [authToken, removeAuthToken, userId]);

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(localTimeZone);
  }, []);

  if (user === undefined) {
    return (
      <div className="flex w-screen h-screen justify-center items-center">
        <LoadingDice />
      </div>
    );
  }

  const handleEmailClick = () => {
    window.location.href = 'mailto:noreply.multigameserver@gmail.com';
  };

  return (
    <div className="select-none scrollbar-hide">
      {timeZone === 'Asia/Calcutta' ? (
        <div className="select-none scrollbar-hide">
          {user ? (
            <RouterNavigation />
          ) : (
            <div>
              <RouterNavigationAuth invitationCode={invitationCode} />
            </div>
          )}
        </div>
      ) : (
        <div class="h-screen w-screen bg-gray-50 flex items-center">
          <div class="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
            <div class="w-full lg:w-1/2 mx-8">
              <div class="text-7xl text-black font-dark font-extrabold mb-8">WinGos</div>
              <p class="text-xl md:text-2xl font-light leading-normal mb-8">
                Sorry, we are only available in India! If you have more query please contact us.
              </p>
              <button
                class="px-5 inline py-3 text-sm
                font-medium leading-5 shadow-2xl
                text-white transition-all duration-400 border
                 border-transparent rounded-lg focus:outline-none
                bg-green-500 active:bg-green-700 hover:bg-green-700"
                onClick={handleEmailClick}>Contact Us</button>
            </div>
            <div class="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
              <img src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg" class="" alt="Page not found" />
            </div>
          </div>
        </div>
      )}
    </div>

  );
}

export default function AppWithAuthProvider() {
  return (
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  );
}
