import React, { useState, useEffect } from "react";
import NumberFormat from "../../utilities/NumberFormat";
import AddAmountModel from "../../components/models/AddAmountModel";
import DebitModel from "../../components/models/DebitModel";
import AccountAddModel from "../../components/models/AccountAddModel";
import ContactModel from "../../components/models/ContactModel";
import CreditTable from "../../components/tables/CreditDataTable";
import DebitDataTable from "../../components/tables/DebitDataTable";
import SharedLink from "../../utilities/SharedLink"
import { getPromotionUserList, getUser, upDateUser } from "../../lib/api/user";
import { getCreditTable } from "../../lib/api/credit";
import { getUPI } from "../../lib/api/upi";
import { sendEmail } from "../../lib/api/contact";
import { getDebitTable, postDebit } from "../../lib/api/debit";
import { deleteAccount, postAccount } from "../../lib/api/account";
import { useAuth } from "../../context/AuthContext";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";
import dark from "../../assets/image/logo.png";
import dp from "../../assets/image/profile.jpg";
import deposit from "../../assets/image/deposit.png";
import depositHis from "../../assets/image/deposit-his.png";
import withdraw from "../../assets/image/withdraw.png";
import withdrawHis from "../../assets/image/withdraw-his.png";
import { postOrder, postValidateOrder } from "../../lib/api/order";
import PromotionModel from "../../components/models/PromotionModel";
import moment from "moment";

function WalletScreen({ userDetail, setUserDetail }) {
  const history = useHistory();
  const RAZOR_PAY_KEY = process.env.RAZOR_PAY_KEY;
  const { authToken, userId } = useAuth();
  const [creditTable, setCreditTable] = useState(undefined);
  const [debitTable, setDebitTable] = useState(undefined);
  const [creditTableVisible, setCreditTableVisible] = useState(false);
  const [debitTableVisible, setDebitTableVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const [isModalVisibleAccount, setIsModalVisibleAccount] = useState(false);
  const [isModalCreditVisible, setIsModalCreditVisible] = useState(false);
  const [upiEmpty, setUpiEmpty] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPromotionModalVisible, setIsPromotionModalVisible] = useState(false);
  const [seePromotion, setSeePromotion] = useState(false);
  const [radio, setRadio] = useState("100");
  const [transactionId, setTransactionId] = useState("");
  const [amount, setAmount] = useState(100);
  const [pageC, setPageC] = useState(1);
  const [pageD, setPageD] = useState(1);
  const [paymentType, setPaymentType] = useState("");
  const [promotionList, setPromotionList] = useState([]);
  const [viewDetails, setViewDetails] = useState(false);

  const baseUrl = window.location.href;
  const parameters = { invitationCode: userDetail?.username };
  const sharedUrl = `${baseUrl}?${new URLSearchParams(parameters).toString()}`;

  const getPromotionUserLisData = (usernames) => {
    getPromotionUserList(authToken, usernames)
      .then((res) => {
        setPromotionList(res);
      })
      // .catch((e) => console.log(e));
  };

  const getUserDetail = () => {
    getUser(authToken, userId)
      .then((res) => {
        setUserDetail(res);
        getPromotionUserLisData(res?.referenceCount?.length === 0 ? [] : res?.referenceCount)
      })
      // .catch((e) => console.log(e));
  };

  const getCreditTableDetails = (pageNo, pageSize) => {
    getCreditTable(authToken, userId, pageNo, pageSize)
      .then((res) => {
        setCreditTable(res);
      })
      // .catch((e) => console.log(e));
  };

  const getDebitTableDetails = (pageNo, pageSize) => {
    getDebitTable(authToken, userId, pageNo, pageSize)
      .then((res) => {
        setDebitTable(res);
      })
      // .catch((e) => console.log(e));
  };

  const getUPIS = () => {
    getUPI(authToken)
      .then((res) => {
        setPaymentType(res[0]?.upi)
      })
      // .catch((e) => console.log(e));
  }

  useEffect(() => {
    getUserDetail();
    getCreditTableDetails(1, 10);
    getDebitTableDetails(1, 10);
    getUPIS();
  }, []);

  useEffect(() => {
    getCreditTableDetails(pageC, 10);
  }, [pageC]);

  useEffect(() => {
    getDebitTableDetails(pageD, 10);
  }, [pageD]);

  const onValueChange = (event) => {
    setRadio(event?.target?.value);
    setAmount(event?.target?.value);
  };

  const onHandleAddAmount = async (setError, value) => {
    if (value === 0 || value < 100) {
      setError("Minimum amount 100 . . !");
    } else {
      // history.push(`/payment?amount=${value}`);
      if (paymentType !== null && paymentType !== "") {
        history.push(`/payment?amount=${value}`);
      } else {
        setIsModalCreditVisible(false);
        const amount = (value * 100);
        // console.log(amount)
        const currency = "INR";
        const receiptId = userDetail?.email;
        const response = await postOrder(authToken,
          {
            amount,
            currency,
            receipt: receiptId,
          });

        const order = await response;
        // console.log(order); 
        var options = {
          key: RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
          amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency,
          name: "WinGos", //your business name
          description: "You can choose your payment method.",
          image: dark,
          order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: async function (response) {
            const body = {
              ...response,
              userId: userId,
              amount: value
            };
            // console.log("response", response);
            const validateRes = await postValidateOrder(authToken, body);
            const jsonRes = await validateRes;
            if (jsonRes?.msg === "success") {
              window.location.reload();
              // console.log("jsonRes", jsonRes);
            } else {
              alert("You'r payment verification is felid please reach out the email!")
            }
          },
          prefill: {
            //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            name: userDetail?.username, //your customer's name
            email: userDetail?.email,
            contact: "+91 " + userDetail?.number ? userDetail?.number : 1234567890, //Provide the customer's phone number for better conversion rates
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
          alert("Payment Failed. Please try some other time!");
        });
        rzp1.open();
      }
    }
  };

  const onHandleCancel = (setError) => {
    setIsModalCreditVisible(false);
    setError("");
    setRadio("100");
    setAmount(100);
    setTransactionId("");
  };

  //Model Debit Hit Button
  const handlePromotionOk = async (
    amount,
    checkBox,
    setCheckBox,
    setError,
    setAmount
  ) => {
    if (amount <= 0) {
      setError("Enter the coins first . . !");
    } else if (amount < 1000) {
      setError(`Minimum 1000 coins`);
    } else if (!checkBox) {
      setError("Agree to continue . . !");
    } else if (amount > userDetail?.bonus) {
      setError(`Enter the less than ${userDetail?.bonus} coins`);
    } else if (userDetail?.totalCredit < 100) {
      setError(`To change the coins first time at least ₹100 rupees need to recharge`);
    } else if (checkBox && amount > 0) {
      let money = amount / 10;
      let bonusChangedCount = userDetail?.bonusChangedCounts?.length === 0 ?
        [
          {
            coins: amount,
            date: new Date()
          }
        ]
        :
        [
          ...userDetail?.bonusChangedCounts,
          {
            coins: amount,
            date: new Date()
          }
        ];
      let data = {
        wallet: userDetail?.wallet + money,
        bonus: userDetail?.bonus - amount,
        bonusChangedCounts: bonusChangedCount
      };
      await upDateUser(authToken, userId, data).then(
        res => {
          setUserDetail(res)
          setError("");
          setCheckBox(false);
          setAmount(0);
          setIsPromotionModalVisible(false);
        }
      )
    }
  };

  //Model Debit Hit Button
  const handleDebitOk = (
    amount,
    checkBox,
    setCheckBox,
    setError,
    account,
    setAccount,
    setAmount
  ) => {
    if (amount <= 0) {
      setError("Enter the amount first . . !");
    } else if (amount <= 150) {
      setError(`Minimum ₹150`);
    } else if (account === undefined) {
      setError("Select the account");
    } else if (!checkBox) {
      setError("Agree to continue . . !");
    } else if (amount > userDetail?.wallet) {
      setError(`Enter the less than ₹${userDetail?.wallet} rupees`);
    } else if (checkBox && amount > 0) {
      let data = {
        amount: amount,
        accountType: account?.upiAddress === "" ? "account" : "upi",
        accountNumber: account?.accountNumber,
        accountIFSC: account?.ifscCode,
        upiAddress: account?.upiAddress,
        userId: userId,
      };
      onSubmitDebit(data);
      setError("");
      setCheckBox(false);
      setAmount(0);
      setAccount(undefined);
      setIsModalVisible(false);
    }
  };

  const handleSendEmail = async (message) => {
    await sendEmail(authToken, {
      email: userDetail?.email,
      message: message
    }).then(res => {
      // console.log(res);
      setContactVisible(false)
    }).catch(e => {
      // console.log(e);
      alert("Please try some other time!")
    })
  }

  const onSubmitDebit = async (data) => {
    await postDebit(authToken, data);
    getUserDetail();
    getDebitTableDetails(pageC, 10);
  };

  const onSubmitAccount = async (data) => {
    await postAccount(authToken, data);
    getUserDetail();
  };

  const onDeleteAccount = async (id) => {
    await deleteAccount(authToken, id);
    getUserDetail();
  };

  return (
    <>
      {userDetail && (
        <div className="min-h-full">
          <main
            className="mx-auto px-4 max-w-7xl flex flex-col sm:px-6  space-y-3"
          >
            <div className="-mt-3 space-y-3 rounded-md bg-white shadow-xl">
              <div className="flex items-center justify-evenly pt-8 pb-5 px-2
               space-x-5">
                <div className={`flex flex-col cursor-pointer
           items-center justify-center h-20 w-20 -mt-4`}
                  onClick={() => setIsModalCreditVisible(true)}>
                  <div className="p-3 bg-white shadow-gray-400 rounded-md shadow">
                    <img className="h-12 w-12" src={deposit} alt="" />
                  </div>
                  <p className="mt-1 font-medium text-xs">Recharge</p>
                </div>
                <div className={`flex flex-col cursor-pointer
           items-center justify-center h-20 w-20 -mt-4`}
                  onClick={() => setIsModalVisible(true)}>
                  <div className="p-3 bg-white shadow-gray-400 rounded-md shadow">
                    <img className="h-12 w-12" src={withdraw} alt="" />
                  </div>
                  <p className="mt-1 font-medium text-xs">Withdraw</p>
                </div>
                <div className={`flex flex-col cursor-pointer
           items-center justify-center h-20 w-20`}
                  onClick={() => setCreditTableVisible(true)}>
                  <div className="p-3 bg-white shadow-gray-400 rounded-md shadow">
                    <img className="h-12 w-12" src={depositHis} alt="" />
                  </div>
                  <p className="mt-1 font-medium text-xs">Recharge history</p>
                </div>
                <div className={`flex flex-col cursor-pointer
            items-center justify-center h-20 w-20`}
                  onClick={() => setDebitTableVisible(true)}>
                  <div className="p-3 bg-white shadow-gray-400 rounded-md shadow">
                    <img className="h-12 w-12" src={withdrawHis} alt="" />
                  </div>
                  <p className="mt-1 font-medium text-xs">Withdrawal history</p>
                </div>
              </div>
            </div>

            <p className="mt-2 text-xl
                  underline text-center font-medium text-black"
              style={{ marginTop: 30 }}>
              Promotion + Bonus Coins
            </p>
            <button
              className="text-center py-1 rounded-md
                  active:bg-green-700 bg-green-500 font-bold
                  text-white hover:bg-green-700
                  focus:outline-none"
              id="open-btn"
              onClick={() => setIsPromotionModalVisible(true)}
            >
              Change to wallet
            </button>
            <button
              className="text-center py-1 rounded-md font-medium
                  text-black shadow-md  
                  focus:outline-none"
              id="open-btn"
              onClick={() => {
                setSeePromotion(!seePromotion)
              }}
            >
              {seePromotion ? "Hide Promotion Transaction" : "See Promotion Transaction"}
            </button>

            {seePromotion &&
              userDetail?.bonusChangedCounts?.map((item, index) => {
                return (
                  <div className=""
                    key={index}>
                    <div className="mx-3 flex justify-between">
                      <div className="flex">
                        <p className="font-medium text-gray-900 truncate">
                          {index + 1})
                        </p>
                        <p className="ml-2 font-medium text-gray-900 truncate">
                          Coins = {item?.coins}
                        </p>
                      </div>
                      <p className="font-medium text-gray-900 truncate">
                        Date = {moment(item?.date).format("DD/MM/YYYY hh:mm a")}
                      </p>
                    </div>
                  </div>
                )
              })
            }

            {promotionList?.length !== 0 && <div
              className=""
              style={{ marginTop: 30 }}>
              <SharedLink url={sharedUrl} />
            </div>
            }

            <div>
              <p className="my-2 text-xl
                  underline text-center font-medium text-black">
                Your Promotion List
              </p>
              {
                promotionList?.length === 0 ?
                  <div className="flex flex-col items-center justify-center">
                    <p className="my-2 text-base
                    text-center font-medium text-gray-700">
                      Promotion List is empty click to share the link and Get 200 Bonus Coins Instantly
                    </p>
                    <div
                      className=""
                      style={{ marginTop: 5 }}>
                      <SharedLink url={sharedUrl} />
                    </div>
                  </div>
                  :
                  promotionList?.map((item, index) => {
                    return (
                      <div className="flex items-center my-2"
                        key={index}>
                        <div className="flex-shrink-0">
                          <img className="h-12 w-12 rounded-full" src={dp} alt="" />
                        </div>
                        <div className="ml-3">
                          <div className="flex items-center space-x-2">
                            <p className="font-medium text-gray-900 text-sm ">Id:</p>
                            <p className="font-medium text-gray-900 truncate">
                              Member-{item?.username}
                            </p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <p className="font-medium text-gray-900 text-sm ">Email:</p>
                            <p className="font-medium text-gray-900 truncate">
                              {item?.email?.slice(0, 4)}*********@gmail.com
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
              }
            </div>

            <div>
              <p className="my-2 text-xl
                  underline text-center font-medium text-black">
                Reward List
              </p>
              {
                userDetail?.referenceBonusCounts?.length === 0 ?
                  <div className="flex flex-col items-center justify-center">
                    <p className="my-2 text-base
                    text-center font-medium text-gray-700">
                      Promotion List is empty click to share the link and Get 200 Bonus Coins Instantly
                    </p>
                    <div
                      className=""
                      style={{ marginTop: 5 }}>
                      <SharedLink url={sharedUrl} />
                    </div>
                  </div>
                  :
                  <table className="border-collapse w-full">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border border-gray-400 px-4 py-2">Period</th>
                        <th className="border border-gray-400 px-4 py-2">User</th>
                        <th className="border border-gray-400 px-4 py-2">Coins</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userDetail?.referenceBonusCounts?.map((item, index) => (
                        <tr key={index}>
                          <td className="border border-gray-400 px-4 py-2">{item?.id}</td>
                          <td className="border border-gray-400 px-4 py-2">Member-{item?.user}</td>
                          <td className="border border-gray-400 px-4 py-2">{item?.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              }
            </div>

            <ContactModel
              isModalVisible={contactVisible}
              setIsModalVisible={setContactVisible}
              handleSendEmail={handleSendEmail}
            />
            <AddAmountModel
              radio={radio}
              amount={amount}
              setAmount={setAmount}
              onValueChange={onValueChange}
              onHandleAddAmount={onHandleAddAmount}
              onHandleCancel={onHandleCancel}
              isModalCreditVisible={isModalCreditVisible}
              transactionId={transactionId}
              setTransactionId={setTransactionId}
            />
            <DebitModel
              walletAmount={userDetail?.wallet}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              accountArray={userDetail?.accountTable}
              handleDebitOk={handleDebitOk}
            />
            <PromotionModel
              walletAmount={userDetail?.bonus}
              isModalVisible={isPromotionModalVisible}
              setIsModalVisible={setIsPromotionModalVisible}
              handlePromotionOk={handlePromotionOk}
            />
            <AccountAddModel
              isModalVisibleAccount={isModalVisibleAccount}
              setIsModalVisibleAccount={setIsModalVisibleAccount}
              userId={userId}
              onSubmitAccount={onSubmitAccount}
              onDeleteAccount={onDeleteAccount}
            />
            <CreditTable
              creditTableVisible={creditTableVisible}
              setCreditTableVisible={setCreditTableVisible}
              creditTableArray={creditTable?.data}
              nextPage={creditTable?.nextPage}
              previousPage={creditTable?.previousPage}
              page={creditTable?.page}
              totalPages={creditTable?.totalPages}
              setPage={setPageC}
            />
            <DebitDataTable
              debitTableVisible={debitTableVisible}
              setDebitTableVisible={setDebitTableVisible}
              debitTableArray={debitTable?.data}
              nextPage={debitTable?.nextPage}
              previousPage={debitTable?.previousPage}
              page={debitTable?.page}
              totalPages={debitTable?.totalPages}
              setPage={setPageD}
              viewDetails={viewDetails}
            />
          </main>
        </div>
      )}
      <Transition.Root show={upiEmpty} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setUpiEmpty(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 select-none z-10 overflow-y-auto">
            <div
              className="flex  items-start mt-16
             justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left px-2 shadow-xl transition-all
                  sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div className="mt-1 text-center">
                    <div className="flex items-center justify-between py-2 ">
                      <h1 className="text-left font-medium text-lg  pr-16">
                        Technical Issue
                      </h1>
                      <IoIosCloseCircle
                        onClick={() => setUpiEmpty(false)}
                        color="#000"
                        className="cursor-pointer text-2xl"
                      />
                    </div>

                    <div className="flex flex-row justify-end items-center my-3 mr-2">
                      <button
                        className="px-2 text-center py-1 rounded-md
                           active:bg-blue-700 bg-blue-500 font-medium
                          text-white hover:bg-blue-700
                           focus:outline-none my-1"
                        id="open-btn"
                        onClick={() => {
                          window.location = "mailto:noreply.multigameserver@gmail.com";
                          setUpiEmpty(false);
                        }}
                      >
                        Send email
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default WalletScreen;
